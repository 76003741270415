import Container from "../../common/layout/Container";
import Columns from "../../common/layout/Columns";
import Column from "../../common/layout/Column";
import {useEffect, useState} from "react";
import {faBinoculars, faMagnifyingGlassChart} from "@fortawesome/free-solid-svg-icons";
import DoughnutChart from "../../common/elements/DoughnutChart";
import Button from "../../common/elements/Button";
import Table from "../../common/elements/Table";
import "../css/insights.css"
import IconCard from "../../common/elements/IconCard";
import {AttributeValuesTable} from "./AttributeValuesTable";
import {experimentation_api_urls} from "../../common/Constants";
import useFetchWithMsal from "../../../useFetchWithMsal";
import Icon from "../../common/elements/Icon";
import StateHandler from "../../common/elements/StateHandler";
import {buildDataState, buildErrorState, buildLoadingState} from "../../../utils/StateUtils";

export const ExperimentStats = (props) => {


    const {execute} = useFetchWithMsal();
    const [selectedRange, setSelectedRange] = useState("2h")
    const [buckets, setBuckets] = useState(buildLoadingState())
    const [ineligibilityReasons, setIneligibilityReasons] = useState(buildLoadingState())
    const [attributes, setAttributes] = useState(buildLoadingState())
    const [eligibleUsers, setEligibleUsers] = useState(buildLoadingState())

    const environment = "PROD"

    const fetchBucketAggregations = () => {
        const [startDate, endDate] = resolveDates()
        execute('GET', experimentation_api_urls.getBucketAggregations(props.experimentKey, environment, startDate, endDate))
            .then((data) => {
                if (!data || !data.results) {
                    return;
                }
                let buckets = []
                let eligibleUsers = {
                    "aggregationIdentifier": "eligible",
                    "counter": 0
                }
                let ineligibleUsers = {
                    "aggregationIdentifier": "ineligible",
                    "counter": 0
                }
                for (const result of data.results) {
                    if (result.aggregationIdentifier !== null) {
                        buckets.push(result)
                        eligibleUsers.counter += result.counter
                    } else {
                        ineligibleUsers.counter += result.counter
                    }
                }
                setBuckets(buildDataState(buckets))
                setEligibleUsers(buildDataState([eligibleUsers, ineligibleUsers]))
            })
            .catch((error) => {
                setBuckets(buildErrorState(error))
                setEligibleUsers(buildErrorState(error))
                console.log(error);
            });
    }


    const fetchAttributesAggregations = () => {
        const [startDate, endDate] = resolveDates()
        execute('GET', experimentation_api_urls.getAttributesAggregations(props.experimentKey, environment, startDate, endDate))
            .then((data) => {
                if (!data || !data.results) {
                    return;
                }
                setAttributes(buildDataState(data.results))
            })
            .catch((error) => {
                setAttributes(buildErrorState(error))
                console.log(error);
            })
    }

    function fetchAttributeValuesAggregationsBucket(attribute, bucketKey = undefined) {
        const [startDate, endDate] = resolveDates()
        return execute('GET', experimentation_api_urls.getAttributeValuesAggregations(props.experimentKey, attribute, environment, startDate, endDate, bucketKey, undefined))
    }

    function fetchAttributeValuesAggregationsReason(attribute, reason = undefined) {
        const [startDate, endDate] = resolveDates()
        return execute('GET', experimentation_api_urls.getAttributeValuesAggregations(props.experimentKey, attribute, environment, startDate, endDate, undefined, reason))
    }

    const fetchIneligibilityReasonsAggregations = () => {
        const [startDate, endDate] = resolveDates()
        execute('GET', experimentation_api_urls.getIneligibilityReasons(props.experimentKey, environment, startDate, endDate))
            .then((data) => {
                if (!data || !data.results) {
                    return;
                }
                setIneligibilityReasons(buildDataState(data.results))
            })
            .catch((error) => {
                setIneligibilityReasons(buildErrorState(error))
                console.log(error);
            })
    }

    function resolveDates() {
        const endDate = new Date()
        endDate.setMinutes(0, 0, 0)
        const startDate = new Date(endDate)
        startDate.setHours(startDate.getHours() - parseInt(selectedRange.substring(0, selectedRange.length)))
        return [startDate.toISOString(), endDate.toISOString()]
    }

    useEffect(() => {
        fetchBucketAggregations()
        fetchAttributesAggregations()
        fetchIneligibilityReasonsAggregations()
    }, [execute, props, selectedRange])

    useEffect(() => {
        setEligibleUsers(buildLoadingState())
        setBuckets(buildLoadingState())
        setAttributes(buildLoadingState())
        setIneligibilityReasons(buildLoadingState())
    }, [props.experimentKey, selectedRange])


    const rangesToConsider = ["48h", "24h", "12h", "2h"]

    const getRangeButtonBackgroundColor = (value) => {
        return selectedRange === value ? "has-background-primary" : ""
    }

    const getRangeButtonTextColor = (value) => {
        return selectedRange === value ? "has-text-white" : ""
    }

    return (
        <Container>
            <div className="card has-background-light pb-5">
                <div className="card-content">
                    <div className="fullwidth">
                        <IconCard icon={faBinoculars} title={"Stats"}/>
                        <div className="fullwidth is-inline-flex">
                            <Button className="is-link has-background-primary"
                                    href={"https://prod-useast-a.online.tableau.com/#/site/tripadvisor/workbooks/1198195/views"}
                                    tooltip={"See your experiment results in Heimdall"}
                            >
                                <Icon icon={faMagnifyingGlassChart}/>
                                <span>Heimdall link</span>
                            </Button>
                            <div className="fa-pull-right ml-auto">
                                {rangesToConsider.map(value => {
                                    return <Button id={value} onClick={() => setSelectedRange(value)}
                                                   tooltip={"See bucketing decision stats for last " + value}
                                                   className={"m-2 " + getRangeButtonBackgroundColor(value)}>
                                                    <span id={value}
                                                          className={getRangeButtonTextColor(value)}>{value}</span>
                                    </Button>
                                })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="divider"/>
                    <Columns className="is-multiline">
                        <Column className="is-one-third">
                            <div className="centered-column">
                                <span className="matching-title">Eligibility Distribution</span>
                            </div>
                            <StateHandler showLoader={eligibleUsers.loading} showError={eligibleUsers.error}>
                                <DoughnutChart data={eligibleUsers.data}
                                               title="Eligibility Distribution"/>
                            </StateHandler>
                        </Column>
                        <Column className="is-one-fifth"/>
                        <Column className="is-one-third">
                            <div className="centered-column">
                                <span className="matching-title">Attributes</span>
                                <StateHandler showLoader={attributes.loading} showError={attributes.error}>
                                    <Table className="mt-2 " headers={{
                                        className: "has-text-white",
                                        values: [{value: "Attribute"}, {
                                            value: "# of logged decisions"
                                        }]
                                    }}
                                           rows={attributes.data?.map((value, index) => {
                                               //a row's data
                                               return {
                                                   id: index,
                                                   values: [{value: value['aggregationIdentifier']}, {value: value['counter']}]
                                               }
                                           })}
                                    />
                                </StateHandler>
                            </div>
                        </Column>
                        <div className="divider"/>
                        <Column className="is-one-third">
                            <div className="centered-column">
                                <span className="matching-title">Buckets Distribution</span>
                            </div>
                            <StateHandler showLoader={buckets.loading} showError={buckets.error}>
                                <DoughnutChart data={buckets.data}
                                               title="Buckets Distribution"/>
                            </StateHandler>
                        </Column>

                        <Column className="is-one-fifth"/>
                        <Column className="is-two-fifths">
                            <AttributeValuesTable
                                experimentKey={props.experimentKey}
                                timeRange={selectedRange}
                                attributes={attributes}
                                optionalSelection={"Bucket"}
                                optionalSelectionOptions={buckets.data?.map((value, index) => {
                                    return {
                                        value: index,
                                        label: value.aggregationIdentifier
                                    }
                                })} tableDataCallback={fetchAttributeValuesAggregationsBucket}/>
                        </Column>
                        <div className="divider"/>
                        <Column className="is-one-third">
                            <div className="centered-column">
                                <span className="matching-title">Ineligibility Reasons</span>
                            </div>
                            <StateHandler showLoader={buckets.loading} showError={buckets.error}>
                                <DoughnutChart data={ineligibilityReasons.data}
                                               title="Ineligibility Reasons"/>
                            </StateHandler>
                        </Column>
                        <Column className="is-one-fifth"/>
                        <Column className="is-two-fifths">
                            <AttributeValuesTable
                                experimentKey={props.experimentKey}
                                timeRange={selectedRange}
                                attributes={attributes}
                                optionalSelection={"Reason Ineligible"}
                                optionalSelectionOptions={ineligibilityReasons.data?.map((value, index) => {
                                    return {
                                        value: index,
                                        label: value.aggregationIdentifier
                                    }
                                })} tableDataCallback={fetchAttributeValuesAggregationsReason}/>
                        </Column>
                    </Columns>
                </div>
            </div>
        </Container>
    );
}